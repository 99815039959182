import { Box, Button, Container, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import React from 'react';
import '../App.css';
import { ArrowKind } from './const/ArrowKind';

export type Callback = (frequency: number, kind: ArrowKind, damashi: boolean) => void;
export type Props = {
    frequency: number, arrowKind: ArrowKind, damashi: boolean, callback: Callback,
};

export function StartTag(props: Props) {
    const [frequency, setFrequency] = React.useState(props.frequency);
    const [arrowKind, setArrowKind] = React.useState(props.arrowKind);
    const [damashi, setDamashi] = React.useState(props.damashi);

    //回数を選択したときのハンドラー
    const handleFrequencyChange = (event: any): void => {
        console.log(event);

        const frequency: number = Number(event.target.value);

        if (frequency > 0) {
            setFrequency(frequency);
        }
    };

    //方向を選択したときのハンドラー
    const handleArrowKindChange = (event: any): void => {
        console.log(event);
        setArrowKind(event.target.value);
    };

    //だましを選択したときのハンドラー
    const handleDamashiChange = (event: any): void => {
        console.log(event);
        const damashi: boolean = event.target.value === "1" ? true : false;
        setDamashi(damashi);
    };

    //開始をクリックしたときの処理
    const onClickStart = () => {
        props.callback(frequency, arrowKind, damashi);
    }


    return (
        <Container maxWidth="sm">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom color="primary" align="center">反射神経トレーニングを開始します</Typography>
                </Grid>

                <Grid item xs={4} justify="center">
                    <TextField id="frequency" label="トレーニング回数" value={frequency} type="number" onChange={handleFrequencyChange} />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel id="arrowKind">方向</InputLabel>
                    <Select
                        labelId="arrowKind"
                        id="arrowKind"
                        value={arrowKind}
                        onChange={handleArrowKindChange}
                    >
                        <MenuItem value={ArrowKind.TwoWay}>左右</MenuItem>
                        <MenuItem value={ArrowKind.FourWay}>上下左右</MenuItem>
                        <MenuItem value={ArrowKind.EightWay}>8方向</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel>だまし</FormLabel>
                    <RadioGroup row value={Number(damashi)} onChange={handleDamashiChange}>
                        <FormControlLabel value={0} control={<Radio />} label="だましなし" />
                        <FormControlLabel value={1} control={<Radio />} label="だましあり" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" onClick={onClickStart}>開始</Button>
                    </Box>
                </Grid>
            </Grid>

        </Container>
    );
}

