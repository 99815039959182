import { useEffect, useState } from 'react';
import { ArrowKind } from './const/ArrowKind';

//ゲーム中のステータス
enum State {
    WAIT, //問題表示前
    SHOW, //問題表示中
}
const ACTIVE_ONE_CSS = "PlayColumnActiveOne";
const ACTIVE_TWO_CSS = "PlayColumnActiveTwo";
const DISABLE_CSS = "PlayColumnDisable";
const DAMASHI_CSS = "DamashiColumn";


export type Callback = () => void;
export type Props = {
    frequency: number, arrowKind: ArrowKind, damashi: boolean, callback: Callback,
};

export function PlayTag(props: Props) {

    //現在の状態
    const [state, setState] = useState(State.WAIT);
    //残り回数
    const [remainFrequency, setRemainFrequency] = useState(props.frequency);
    //盤面
    const [board, setBoard] = useState(Array<string>(9).fill(""));

    //状態を監視
    useEffect(() => {
        //指定回数実行したら終了
        if (remainFrequency === 0) {
            props.callback();
        }

        let id: NodeJS.Timeout;

        switch (state) {
            case State.WAIT:

                setBoard(Array<string>(9).fill(""));

                id = setTimeout(() => {
                    setState(State.SHOW);
                }, Math.random() * 2000 + 2000);
                break;
            case State.SHOW:
                setBoard(createBoard(props.arrowKind, props.damashi));
                id = setTimeout(() => {
                    setRemainFrequency((prev: number) => prev - 1);
                    setState(State.WAIT);
                }, 1000);
                break;

            default:
                break;
        }

        return (() => {
            clearTimeout(id);
        });
        // remainFrequencyが変わるタイミングではこの処理は行わないので第二引数から除外する
        // propsが変わるタイミングではこの処理は行わないので第二引数から除外する
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    //だましの設定
    let damashi: JSX.Element;
    if (props.damashi && board[4] === DISABLE_CSS) {
        let moji = getRandomInt(0, 2) === 0 ? "赤" : "青";
        let color = getRandomInt(0, 2) === 0 ? "ActiveColorOne" : "ActiveColorTwo";
        damashi = <p className={color}>{moji}</p>
    } else {
        damashi = <p></p>;
    }


    console.debug("play render " + new Date().toLocaleTimeString());

    return (
        <div className="Play">
            <div className="PlayInfo">残り回数：{remainFrequency}</div>
            {/* １行目*/}
            <div className="PlayRow">
                <div className={"PlayColumn " + board[0]}>1</div>
                <div className={"PlayColumn " + board[1]}>2</div>
                <div className={"PlayColumn " + board[2]}>3</div>
            </div>
            {/* ２行目*/}
            <div className="PlayRow">
                <div className={"PlayColumn " + board[3]}>4</div>
                <div className="PlayColumn DamashiColumn">{damashi}</div>
                <div className={"PlayColumn " + board[5]}>6</div>
            </div>
            {/* ３行目*/}
            <div className="PlayRow">
                <div className={"PlayColumn " + board[6]}>7</div>
                <div className={"PlayColumn " + board[7]}>8</div>
                <div className={"PlayColumn " + board[8]}>9</div>
            </div>



        </div>
    )
}

//問題を作成する
function createBoard(arrowKind: ArrowKind, damashi: boolean): Array<string> {
    const board = Array<string>(9).fill("");

    //選択できな場所に目印をつける
    switch (arrowKind) {
        case ArrowKind.TwoWay:
            board[0] = DISABLE_CSS;
            board[1] = DISABLE_CSS;
            board[2] = DISABLE_CSS;
            board[4] = DISABLE_CSS;
            board[6] = DISABLE_CSS;
            board[7] = DISABLE_CSS;
            board[8] = DISABLE_CSS;
            break;
        case ArrowKind.FourWay:
            board[0] = DISABLE_CSS;
            board[2] = DISABLE_CSS;
            board[4] = DISABLE_CSS;
            board[6] = DISABLE_CSS;
            board[8] = DISABLE_CSS;
            break;
        case ArrowKind.EightWay:
            board[4] = DISABLE_CSS;
            break;
        default:
            break;
    }

    while (true) {
        let num = getRandomInt(0, 9);
        //中央が選択されないようにする
        if (board[num] === "") {
            board[num] = ACTIVE_ONE_CSS
            break;
        }
    }

    if (damashi) {
        while (true) {
            let num = getRandomInt(0, 9);
            //中央と1番目の場所が選択されないようにする
            if (board[num] === "") {
                board[num] = ACTIVE_TWO_CSS;
                break;
            }
        }
    }

    return board;
}

//ランダムな整数を取得する
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

