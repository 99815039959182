import React, { useEffect, useState } from 'react';

export type Callback = () => void;

export function CountDownTag(props: { callback: Callback }) {

    const [currentNumber, setCurrentNumber] = useState(3);
    const callback = props.callback;

    useEffect(() => {
        //カウントダウンが完了
        if (currentNumber === 0) {
            callback();
        }
        const id = setTimeout(() => {
            setCurrentNumber(currentNumber - 1);
        }, 1000);

        return (() => {
            clearTimeout(id);
        });
        // callbackが変わるタイミングではこの処理は行わないので第二引数から除外する
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNumber]);

    return (
        <div className="CountDown">{currentNumber}</div>
    )
}

