import { useState } from 'react';
import '../App.css';
import * as Start from './Start';
import * as CountDown from './CountDown';
import * as Play from './Play';
import { PlayStatus } from './const/PlayStatus';
import { ArrowKind } from './const/ArrowKind';

export default function App() {

  //現在の画面
  const [currentPlayStatus, setCurrentPlayStatus] = useState(PlayStatus.NOT_START);
  const [frequency, setFrequency] = useState(30);
  const [arrowKind, setArrowKind] = useState(ArrowKind.FourWay);
  const [damashi, setDamashi] = useState(false);


  //カウントダウンスタート
  const startCountDown: Start.Callback = (frequency: number, arrowKind: ArrowKind, damashi: boolean) => {
    setCurrentPlayStatus(PlayStatus.COUNT_DOWN);
    setFrequency(frequency);
    setArrowKind(arrowKind);
    setDamashi(damashi);
  }

  //ゲームスタート
  const startPlay: CountDown.Callback = () => {
    setCurrentPlayStatus(PlayStatus.PLAYING);
  }

  //ゲーム終了
  const finish: Play.Callback = () => {
    setCurrentPlayStatus(PlayStatus.NOT_START);
  }

  let inner: JSX.Element;

  switch (currentPlayStatus) {
    case PlayStatus.NOT_START:
      inner = <Start.StartTag frequency={frequency} arrowKind={arrowKind} damashi={damashi} callback={startCountDown}></Start.StartTag>
      break;
    case PlayStatus.COUNT_DOWN:
      inner = <CountDown.CountDownTag callback={startPlay}></CountDown.CountDownTag>
      break;
    case PlayStatus.PLAYING:
      inner = <Play.PlayTag frequency={frequency} arrowKind={arrowKind} damashi={damashi} callback={finish}></Play.PlayTag>
      break;
    default:
      inner = <h1>error!!</h1>;
      break;
  }

  console.debug("app render " + new Date().toLocaleTimeString());

  return (
    <div>
      {inner}
    </div>
  );
}
